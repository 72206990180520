const _SFX_PLURALIZE = "s";

export const PREFERENCES = "Preferences";
export const FEATURES = "Features";
export const SETTINGS = "Settings";
export const TITLE = "Title";
export const AUTHOR = "Author";
export const TIMESTAMP = "Timestamp";
export const REPLIES = "Replies";
export const BODY = "Body";
export const BUSINESS_DATE = "BusinessDate";
export const CHILDREN = "Children";
export const COMMENTS = "Comments";
export const CATEGORY = "Category";
export const LEVEL = "Level";
export const TYPE = "Type";
export const SUPER_TYPE = "SuperType";
export const VALUES = "Values";
export const PERIOD = "Period";
export const PRIMARY_REFERENCE = "PrimaryReference";
export const ADDITIONAL_REFERENCES = "AdditionalReferences";
export const NAVIGATE = "Navigate";
export const REFERENCE = "Reference";
export const REFERENCES = REFERENCE + _SFX_PLURALIZE;
export const TEMPORAL = "Temporal";
export const AGGREGATION = "Aggregation";
export const DIMENSION = "Dimension";
export const ENTITY = "Entity";
export const REGION = "Region";
export const GEO = "Geo";
export const LOCATION = "Location";
export const LOCATION_TYPE = "LocationType";
export const PERSON = "Person";
export const PEOPLE = "People";
export const SERIES = "Series";
export const SNAPSHOT = "Snapshot";
export const OWNER = "Owner";
export const STATUS = "Status";
export const START = "Start";
export const END = "End";
export const DUE = "Due";
export const STATE = "State";
export const CHANGED_AT = "ChangedAt";
export const HISTORY = "History";
export const RECURRENCE = "Recurrence";
export const BUSINESS_DATE_RANGE = BUSINESS_DATE + "Range";
export const CALENDAR_DATE_RANGE = "CalendarDateRange";
export const PRIMARY = "Primary";
export const ADDITIONAL = "Additional";
export const DEFAULT_CONTEXT = "DefaultContext";

export const META_TYPE_NAME = "__typename";

export const OPEN = "Open";
export const RECURRING = "Recurring";
export const CANCELLED = "Cancelled";
export const COMPLETE = "Complete";

export const SCALAR_ID = "id";
export const SCALAR_DISPLAY_NAME = "displayName";
export const SCALAR_NICKNAME = "nickName";
export const SCALAR_AVATAR_URL = "avatarURL";
export const SCALAR_NAME = "name";
export const SCALAR_GUID = "guid";
export const SCALAR_SNAPSHOT_GUID = "snapshotGUID";
export const SCALAR_CODE = "code";
export const SCALAR_DESCRIPTION = "description";
export const SCALAR_SEQUENCE = "sequence";
export const SCALAR_EXISTS = "exists";
export const SCALAR_EMAIL = "email";
export const SCALAR_STATUS = "status";
export const SCALAR_ROLE = "role";
export const SCALAR_PERCENT = "percent";
export const SCALAR_COMMENT = "comment";
export const SCALAR_AS_STRING = "asString";
export const SCALAR_FORMATTER = "formatter";
export const SCALAR_TOKEN = "token";
export const SCALAR_LANGUAGE = "language";
export const SCALAR_PLACEHOLDER = "placeholder";
export const SCALAR_VALUE = "value";
export const SCALAR_VALUES = "values";
export const SCALAR_DATA = "data";
export const SCALAR_NAME_REF = "nameRef";
export const SCALAR_PRIORITY = "priority";
export const SCALAR_TYPE = "type";
export const SCALAR_CONFIG = "config";
export const SCALAR_PROVIDER = "provider";
export const SCALAR_TYPE_ID = "typeID";
export const SCALAR_URL = "url";
export const SCALAR_FRAGMENT = "fragment";
export const SCLAR_QUERYPARAMS = "queryParams";
export const SCALAR_LOCATION_TYPE = "locationType";
export const SCALAR_YEAR = "year";
export const SCALAR_QUARTER = "quarter";
export const SCALAR_MONTH = "month";
export const SCALAR_WEEK = "week";
export const SCALAR_DAY = "day";
export const SCALAR_HOUR = "hour";
export const SCALAR_MINUTE = "minute";
export const SCALAR_MINUTES = "minutes";
export const SCALAR_SECOND = "second";
export const SCALAR_SECONDS = "seconds";
export const SCALAR_YEARS = "years";
export const SCALAR_QUARTERS = "quarters";
export const SCALAR_MONTHS = "months";
export const SCALAR_WEEKS = "weeks";
export const SCALAR_DAYS = "days";
export const SCALAR_HOURS = "hours";
export const SCALAR_ENABLED = "enabled";
export const SCALAR_USER = "user";
export const SCALAR_DEFAULT = "default";

export const FROM = "From";
export const TO = "To";
export const LAST = "Last";
export const NEXT = "Next";

// DatePicker
export const CALENDAR_DATETIME = "calendarDatetime";
export const SCALAR_AGGREGATION = "aggregation";
export const DAY = "Day";
export const RANGES_BY_TYPE = "rangesByType";
export const AS_DATE = "asDate";
